export default {
  v: '5.9.4',
  fr: 25,
  ip: 0,
  op: 79,
  w: 318,
  h: 318,
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 37,
              s: [-180]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 37,
              s: [270.918, 298.798, 0],
              to: [-0.236, -6.216, 0],
              ti: [0.236, 6.216, 0]
            },
            { t: 79, s: [269.5, 261.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 40,
              s: [0, 0, 100]
            },
            { t: 46, s: [134, 134, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.102, -0.022],
                    [0, 0],
                    [0, 0],
                    [0.353, -0.353],
                    [0.096, -0.485],
                    [0, 0],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0.019, 0.101],
                    [0, 0],
                    [0.353, 0.353],
                    [0.492, 0.102],
                    [0, 0],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08],
                    [-0.102, 0.022],
                    [0, 0],
                    [-0.353, 0.353],
                    [-0.096, 0.485],
                    [0, 0],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [-0.019, -0.101],
                    [0, 0],
                    [-0.353, -0.353],
                    [-0.492, -0.102],
                    [0, 0],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.492, 0.102],
                    [-0.353, 0.353],
                    [0, 0],
                    [-0.019, 0.101],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [0, 0],
                    [-0.096, -0.485],
                    [-0.353, -0.353],
                    [0, 0],
                    [-0.102, -0.022],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08],
                    [0, 0],
                    [0.492, -0.102],
                    [0.353, -0.353],
                    [0, 0],
                    [0.019, -0.101],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0, 0],
                    [0.096, 0.485],
                    [0.353, 0.353],
                    [0, 0],
                    [0.102, 0.022],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08]
                  ],
                  v: [
                    [3.641, 0.439],
                    [2.627, 0.645],
                    [2.627, 0.645],
                    [1.332, 1.342],
                    [0.645, 2.627],
                    [0.439, 3.641],
                    [0.286, 3.899],
                    [0, 4],
                    [-0.285, 3.899],
                    [-0.439, 3.641],
                    [-0.645, 2.627],
                    [-1.332, 1.342],
                    [-2.627, 0.645],
                    [-3.641, 0.439],
                    [-3.899, 0.282],
                    [-4, 0],
                    [-3.899, -0.282],
                    [-3.641, -0.439],
                    [-2.627, -0.645],
                    [-1.332, -1.342],
                    [-0.645, -2.627],
                    [-0.439, -3.641],
                    [-0.285, -3.899],
                    [0, -4],
                    [0.286, -3.899],
                    [0.439, -3.641],
                    [0.645, -2.627],
                    [1.332, -1.342],
                    [2.627, -0.645],
                    [3.641, -0.439],
                    [3.899, -0.282],
                    [4, 0],
                    [3.899, 0.282]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 37,
      op: 1037,
      st: 37,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 47,
              s: [-90]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 47,
              s: [77, 309.5, 0],
              to: [0.083, -4.833, 0],
              ti: [-0.083, 4.833, 0]
            },
            { t: 79, s: [77.5, 280.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 53,
              s: [0, 0, 100]
            },
            { t: 59, s: [83, 83, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, -0.016],
                    [0, 0],
                    [0, 0],
                    [0.264, -0.264],
                    [0.072, -0.364],
                    [0, 0],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0.014, 0.076],
                    [0, 0],
                    [0.264, 0.264],
                    [0.369, 0.077],
                    [0, 0],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06],
                    [-0.076, 0.016],
                    [0, 0],
                    [-0.264, 0.264],
                    [-0.072, 0.364],
                    [0, 0],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [-0.014, -0.076],
                    [0, 0],
                    [-0.264, -0.264],
                    [-0.369, -0.077],
                    [0, 0],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.369, 0.077],
                    [-0.264, 0.264],
                    [0, 0],
                    [-0.014, 0.076],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [0, 0],
                    [-0.072, -0.364],
                    [-0.264, -0.264],
                    [0, 0],
                    [-0.076, -0.016],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06],
                    [0, 0],
                    [0.369, -0.077],
                    [0.264, -0.264],
                    [0, 0],
                    [0.014, -0.076],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0, 0],
                    [0.072, 0.364],
                    [0.264, 0.264],
                    [0, 0],
                    [0.076, 0.016],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06]
                  ],
                  v: [
                    [2.731, 0.329],
                    [1.97, 0.483],
                    [1.97, 0.483],
                    [0.999, 1.006],
                    [0.483, 1.97],
                    [0.329, 2.73],
                    [0.214, 2.924],
                    [0, 3],
                    [-0.214, 2.924],
                    [-0.329, 2.73],
                    [-0.483, 1.97],
                    [-0.999, 1.006],
                    [-1.97, 0.483],
                    [-2.731, 0.329],
                    [-2.924, 0.211],
                    [-3, 0],
                    [-2.924, -0.211],
                    [-2.731, -0.329],
                    [-1.97, -0.483],
                    [-0.999, -1.006],
                    [-0.483, -1.97],
                    [-0.329, -2.73],
                    [-0.214, -2.924],
                    [0, -3],
                    [0.214, -2.924],
                    [0.329, -2.73],
                    [0.483, -1.97],
                    [0.999, -1.006],
                    [1.97, -0.483],
                    [2.731, -0.329],
                    [2.924, -0.211],
                    [3, 0],
                    [2.924, 0.211]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 47,
      op: 1047,
      st: 47,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 41,
              s: [-80]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 41,
              s: [37.5, 304, 0],
              to: [0, -5.917, 0],
              ti: [0, 5.917, 0]
            },
            { t: 79, s: [37.5, 268.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 45,
              s: [0, 0, 100]
            },
            { t: 51, s: [89, 89, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.127, -0.027],
                    [0, 0],
                    [0, 0],
                    [0.441, -0.441],
                    [0.12, -0.607],
                    [0, 0],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0.024, 0.126],
                    [0, 0],
                    [0.441, 0.441],
                    [0.615, 0.128],
                    [0, 0],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1],
                    [-0.127, 0.027],
                    [0, 0],
                    [-0.441, 0.441],
                    [-0.12, 0.607],
                    [0, 0],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [-0.024, -0.126],
                    [0, 0],
                    [-0.441, -0.441],
                    [-0.615, -0.128],
                    [0, 0],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.615, 0.128],
                    [-0.441, 0.441],
                    [0, 0],
                    [-0.024, 0.126],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [0, 0],
                    [-0.12, -0.607],
                    [-0.441, -0.441],
                    [0, 0],
                    [-0.127, -0.027],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1],
                    [0, 0],
                    [0.615, -0.128],
                    [0.441, -0.441],
                    [0, 0],
                    [0.024, -0.126],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0, 0],
                    [0.12, 0.607],
                    [0.441, 0.441],
                    [0, 0],
                    [0.127, 0.027],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1]
                  ],
                  v: [
                    [4.551, 0.549],
                    [3.284, 0.806],
                    [3.284, 0.806],
                    [1.665, 1.678],
                    [0.806, 3.284],
                    [0.549, 4.551],
                    [0.357, 4.873],
                    [0, 5],
                    [-0.357, 4.873],
                    [-0.549, 4.551],
                    [-0.806, 3.284],
                    [-1.665, 1.678],
                    [-3.284, 0.806],
                    [-4.551, 0.549],
                    [-4.874, 0.352],
                    [-5, 0],
                    [-4.874, -0.352],
                    [-4.551, -0.549],
                    [-3.284, -0.806],
                    [-1.665, -1.678],
                    [-0.806, -3.284],
                    [-0.549, -4.551],
                    [-0.357, -4.873],
                    [0, -5],
                    [0.357, -4.873],
                    [0.549, -4.551],
                    [0.806, -3.284],
                    [1.665, -1.678],
                    [3.284, -0.806],
                    [4.551, -0.549],
                    [4.874, -0.352],
                    [5, 0],
                    [4.874, 0.352]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 39,
      op: 1049,
      st: 49,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 47,
              s: [-100]
            },
            { t: 78, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 47,
              s: [217.25, 75.75, 0],
              to: [-0.292, -3.875, 0],
              ti: [0.292, 3.875, 0]
            },
            { t: 79, s: [215.5, 52.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 49,
              s: [0, 0, 100]
            },
            { t: 55, s: [100, 100, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [2.299, 0.564],
                    [1.166, 1.174],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 47,
      op: 1047,
      st: 47,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 52, s: [180] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [269.5, 261.5, 0],
              to: [0, -15.5, 0],
              ti: [0, 15.5, 0]
            },
            { t: 52, s: [269.5, 168.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 42,
              s: [134, 134, 100]
            },
            { t: 48, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.102, -0.022],
                    [0, 0],
                    [0, 0],
                    [0.353, -0.353],
                    [0.096, -0.485],
                    [0, 0],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0.019, 0.101],
                    [0, 0],
                    [0.353, 0.353],
                    [0.492, 0.102],
                    [0, 0],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08],
                    [-0.102, 0.022],
                    [0, 0],
                    [-0.353, 0.353],
                    [-0.096, 0.485],
                    [0, 0],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [-0.019, -0.101],
                    [0, 0],
                    [-0.353, -0.353],
                    [-0.492, -0.102],
                    [0, 0],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.492, 0.102],
                    [-0.353, 0.353],
                    [0, 0],
                    [-0.019, 0.101],
                    [-0.08, 0.066],
                    [-0.104, 0],
                    [-0.08, -0.066],
                    [0, 0],
                    [-0.096, -0.485],
                    [-0.353, -0.353],
                    [0, 0],
                    [-0.102, -0.022],
                    [-0.065, -0.08],
                    [0, -0.102],
                    [0.065, -0.08],
                    [0, 0],
                    [0.492, -0.102],
                    [0.353, -0.353],
                    [0, 0],
                    [0.019, -0.101],
                    [0.08, -0.066],
                    [0.104, 0],
                    [0.08, 0.066],
                    [0, 0],
                    [0.096, 0.485],
                    [0.353, 0.353],
                    [0, 0],
                    [0.102, 0.022],
                    [0.065, 0.08],
                    [0, 0.102],
                    [-0.065, 0.08]
                  ],
                  v: [
                    [3.641, 0.439],
                    [2.627, 0.645],
                    [2.627, 0.645],
                    [1.332, 1.342],
                    [0.645, 2.627],
                    [0.439, 3.641],
                    [0.286, 3.899],
                    [0, 4],
                    [-0.285, 3.899],
                    [-0.439, 3.641],
                    [-0.645, 2.627],
                    [-1.332, 1.342],
                    [-2.627, 0.645],
                    [-3.641, 0.439],
                    [-3.899, 0.282],
                    [-4, 0],
                    [-3.899, -0.282],
                    [-3.641, -0.439],
                    [-2.627, -0.645],
                    [-1.332, -1.342],
                    [-0.645, -2.627],
                    [-0.439, -3.641],
                    [-0.285, -3.899],
                    [0, -4],
                    [0.286, -3.899],
                    [0.439, -3.641],
                    [0.645, -2.627],
                    [1.332, -1.342],
                    [2.627, -0.645],
                    [3.641, -0.439],
                    [3.899, -0.282],
                    [4, 0],
                    [3.899, 0.282]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 35, s: [180] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [77.5, 280.5, 0],
              to: [0, -5.5, 0],
              ti: [0, 5.5, 0]
            },
            { t: 32, s: [77.5, 247.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 13,
              s: [83, 83, 100]
            },
            { t: 19, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.076, -0.016],
                    [0, 0],
                    [0, 0],
                    [0.264, -0.264],
                    [0.072, -0.364],
                    [0, 0],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0.014, 0.076],
                    [0, 0],
                    [0.264, 0.264],
                    [0.369, 0.077],
                    [0, 0],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06],
                    [-0.076, 0.016],
                    [0, 0],
                    [-0.264, 0.264],
                    [-0.072, 0.364],
                    [0, 0],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [-0.014, -0.076],
                    [0, 0],
                    [-0.264, -0.264],
                    [-0.369, -0.077],
                    [0, 0],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.369, 0.077],
                    [-0.264, 0.264],
                    [0, 0],
                    [-0.014, 0.076],
                    [-0.06, 0.049],
                    [-0.078, 0],
                    [-0.06, -0.049],
                    [0, 0],
                    [-0.072, -0.364],
                    [-0.264, -0.264],
                    [0, 0],
                    [-0.076, -0.016],
                    [-0.049, -0.06],
                    [0, -0.077],
                    [0.049, -0.06],
                    [0, 0],
                    [0.369, -0.077],
                    [0.264, -0.264],
                    [0, 0],
                    [0.014, -0.076],
                    [0.06, -0.049],
                    [0.078, 0],
                    [0.06, 0.049],
                    [0, 0],
                    [0.072, 0.364],
                    [0.264, 0.264],
                    [0, 0],
                    [0.076, 0.016],
                    [0.049, 0.06],
                    [0, 0.077],
                    [-0.049, 0.06]
                  ],
                  v: [
                    [2.731, 0.329],
                    [1.97, 0.483],
                    [1.97, 0.483],
                    [0.999, 1.006],
                    [0.483, 1.97],
                    [0.329, 2.73],
                    [0.214, 2.924],
                    [0, 3],
                    [-0.214, 2.924],
                    [-0.329, 2.73],
                    [-0.483, 1.97],
                    [-0.999, 1.006],
                    [-1.97, 0.483],
                    [-2.731, 0.329],
                    [-2.924, 0.211],
                    [-3, 0],
                    [-2.924, -0.211],
                    [-2.731, -0.329],
                    [-1.97, -0.483],
                    [-0.999, -1.006],
                    [-0.483, -1.97],
                    [-0.329, -2.73],
                    [-0.214, -2.924],
                    [0, -3],
                    [0.214, -2.924],
                    [0.329, -2.73],
                    [0.483, -1.97],
                    [0.999, -1.006],
                    [1.97, -0.483],
                    [2.731, -0.329],
                    [2.924, -0.211],
                    [3, 0],
                    [2.924, 0.211]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 30, s: [80] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [37.5, 268.5, 0],
              to: [0, -8.167, 0],
              ti: [0, 8.167, 0]
            },
            { t: 24, s: [37.5, 219.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 15,
              s: [89, 89, 100]
            },
            { t: 21, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.127, -0.027],
                    [0, 0],
                    [0, 0],
                    [0.441, -0.441],
                    [0.12, -0.607],
                    [0, 0],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0.024, 0.126],
                    [0, 0],
                    [0.441, 0.441],
                    [0.615, 0.128],
                    [0, 0],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1],
                    [-0.127, 0.027],
                    [0, 0],
                    [-0.441, 0.441],
                    [-0.12, 0.607],
                    [0, 0],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [-0.024, -0.126],
                    [0, 0],
                    [-0.441, -0.441],
                    [-0.615, -0.128],
                    [0, 0],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.615, 0.128],
                    [-0.441, 0.441],
                    [0, 0],
                    [-0.024, 0.126],
                    [-0.1, 0.082],
                    [-0.13, 0],
                    [-0.1, -0.082],
                    [0, 0],
                    [-0.12, -0.607],
                    [-0.441, -0.441],
                    [0, 0],
                    [-0.127, -0.027],
                    [-0.082, -0.1],
                    [0, -0.128],
                    [0.082, -0.1],
                    [0, 0],
                    [0.615, -0.128],
                    [0.441, -0.441],
                    [0, 0],
                    [0.024, -0.126],
                    [0.1, -0.082],
                    [0.13, 0],
                    [0.1, 0.082],
                    [0, 0],
                    [0.12, 0.607],
                    [0.441, 0.441],
                    [0, 0],
                    [0.127, 0.027],
                    [0.082, 0.1],
                    [0, 0.128],
                    [-0.082, 0.1]
                  ],
                  v: [
                    [4.551, 0.549],
                    [3.284, 0.806],
                    [3.284, 0.806],
                    [1.665, 1.678],
                    [0.806, 3.284],
                    [0.549, 4.551],
                    [0.357, 4.873],
                    [0, 5],
                    [-0.357, 4.873],
                    [-0.549, 4.551],
                    [-0.806, 3.284],
                    [-1.665, 1.678],
                    [-3.284, 0.806],
                    [-4.551, 0.549],
                    [-4.874, 0.352],
                    [-5, 0],
                    [-4.874, -0.352],
                    [-4.551, -0.549],
                    [-3.284, -0.806],
                    [-1.665, -1.678],
                    [-0.806, -3.284],
                    [-0.549, -4.551],
                    [-0.357, -4.873],
                    [0, -5],
                    [0.357, -4.873],
                    [0.549, -4.551],
                    [0.806, -3.284],
                    [1.665, -1.678],
                    [3.284, -0.806],
                    [4.551, -0.549],
                    [4.874, -0.352],
                    [5, 0],
                    [4.874, 0.352]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0]
            },
            { t: 28, s: [100] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [215.5, 52.5, 0],
              to: [0, -3.667, 0],
              ti: [0, 3.667, 0]
            },
            { t: 24, s: [215.5, 30.5, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
              o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
              t: 17,
              s: [100, 100, 100]
            },
            { t: 23, s: [0, 0, 100] }
          ],
          ix: 6,
          l: 2
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.089, -0.019],
                    [0, 0],
                    [0, 0],
                    [0.309, -0.309],
                    [0.084, -0.425],
                    [0, 0],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0.017, 0.088],
                    [0, 0],
                    [0.309, 0.309],
                    [0.43, 0.09],
                    [0, 0],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07],
                    [-0.089, 0.019],
                    [0, 0],
                    [-0.309, 0.309],
                    [-0.084, 0.425],
                    [0, 0],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [-0.017, -0.088],
                    [0, 0],
                    [-0.309, -0.309],
                    [-0.43, -0.09],
                    [0, 0],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-0.43, 0.09],
                    [-0.309, 0.309],
                    [0, 0],
                    [-0.017, 0.088],
                    [-0.07, 0.057],
                    [-0.091, 0],
                    [-0.07, -0.057],
                    [0, 0],
                    [-0.084, -0.425],
                    [-0.309, -0.309],
                    [0, 0],
                    [-0.089, -0.019],
                    [-0.057, -0.07],
                    [0, -0.09],
                    [0.057, -0.07],
                    [0, 0],
                    [0.43, -0.09],
                    [0.309, -0.309],
                    [0, 0],
                    [0.017, -0.088],
                    [0.07, -0.057],
                    [0.091, 0],
                    [0.07, 0.057],
                    [0, 0],
                    [0.084, 0.425],
                    [0.309, 0.309],
                    [0, 0],
                    [0.089, 0.019],
                    [0.057, 0.07],
                    [0, 0.09],
                    [-0.057, 0.07]
                  ],
                  v: [
                    [3.186, 0.384],
                    [2.299, 0.564],
                    [2.299, 0.564],
                    [1.166, 1.174],
                    [0.564, 2.299],
                    [0.384, 3.186],
                    [0.25, 3.411],
                    [0, 3.5],
                    [-0.25, 3.411],
                    [-0.384, 3.186],
                    [-0.564, 2.299],
                    [-1.166, 1.174],
                    [-2.299, 0.564],
                    [-3.186, 0.384],
                    [-3.412, 0.246],
                    [-3.5, 0],
                    [-3.412, -0.246],
                    [-3.186, -0.384],
                    [-2.299, -0.564],
                    [-1.166, -1.174],
                    [-0.564, -2.299],
                    [-0.384, -3.186],
                    [-0.25, -3.411],
                    [0, -3.5],
                    [0.25, -3.411],
                    [0.384, -3.186],
                    [0.564, -2.299],
                    [1.166, -1.174],
                    [2.299, -0.564],
                    [3.186, -0.384],
                    [3.412, -0.246],
                    [3.5, 0],
                    [3.412, 0.246]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.730751395226, 0.730751395226, 0.730751395226, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [400, 400], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [182.073, 128.753, 0], ix: 2, l: 2 },
        a: { a: 0, k: [28.56, 15.212, 0], ix: 1, l: 2 },
        s: { a: 0, k: [415, 415, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.309, 0.085],
                    [-0.013, 0.521],
                    [0, 0],
                    [-7.964, 0],
                    [0, 0],
                    [-0.103, 1.413],
                    [0, 0],
                    [-1.083, -0.695],
                    [-3.235, -4.527],
                    [0, 0],
                    [0.282, -0.396],
                    [0, 0],
                    [4.673, -3.002],
                    [0.422, 0.874],
                    [0.016, 0.225],
                    [0, 0],
                    [0.061, 1.688],
                    [0, 0],
                    [2.53, -4.508],
                    [0, 0],
                    [0.16, -0.098]
                  ],
                  o: [
                    [-0.499, -0.138],
                    [0, 0],
                    [0.192, -8.002],
                    [0, 0],
                    [0.059, -1.414],
                    [0, 0],
                    [0.093, -1.288],
                    [4.673, 3.002],
                    [0, 0],
                    [0.282, 0.396],
                    [0, 0],
                    [-3.235, 4.527],
                    [-0.892, 0.573],
                    [-0.089, -0.185],
                    [0, 0],
                    [-0.122, -1.685],
                    [0, 0],
                    [-5.091, -0.77],
                    [0, 0],
                    [-0.097, 0.173],
                    [-0.259, 0.16]
                  ],
                  v: [
                    [-16.124, 14.686],
                    [-16.952, 13.566],
                    [-16.82, 7.977],
                    [-2.156, -6.412],
                    [0.682, -6.412],
                    [0.924, -10.653],
                    [1.077, -12.764],
                    [3.683, -14.077],
                    [15.654, -2.68],
                    [16.684, -1.24],
                    [16.684, 0.083],
                    [15.654, 1.523],
                    [3.683, 12.919],
                    [1.24, 12.23],
                    [1.077, 11.606],
                    [0.924, 9.495],
                    [0.65, 4.436],
                    [-0.15, 4.316],
                    [-12.807, 10.523],
                    [-14.842, 14.15],
                    [-15.237, 14.561]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.729411764706, 0.729411764706, 0.729411764706, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [28.588, 15.022], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [157.591, 164.668, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'rc',
              d: 1,
              s: { a: 0, k: [165, 165], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              r: { a: 0, k: 44, ix: 4 },
              hd: false
            },
            {
              ty: 'tm',
              s: { a: 0, k: 0, ix: 1 },
              e: { a: 0, k: 75, ix: 2 },
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.41], y: [1] },
                    o: { x: [0.59], y: [0] },
                    t: 0,
                    s: [32]
                  },
                  { t: 53, s: [392] }
                ],
                ix: 3
              },
              m: 1,
              ix: 2,
              hd: false
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.730743408203, 0.730743408203, 0.730743408203, 1],
                ix: 3
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 26, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 4,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [-2.635, -5.668], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [159, 159, 0], ix: 2, l: 2 },
        a: { a: 0, k: [23, 22.5, 0], ix: 1, l: 2 },
        s: { a: 0, k: [415, 415, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.82, -0.728],
                    [0.012, -0.248],
                    [0.266, -2.287],
                    [3.002, -0.337],
                    [7.573, 0.851],
                    [0.348, 2.989],
                    [-0.905, 7.777],
                    [-3.001, 0.337],
                    [-5.071, -0.179],
                    [-0.026, 0.368],
                    [0, 0],
                    [-0.011, 0.092],
                    [0.441, 0.015],
                    [5.17, -0.58],
                    [0.534, -4.587],
                    [-0.935, -8.039],
                    [-4.54, -0.51],
                    [-7.822, 0.878],
                    [-0.534, 4.588],
                    [-0.07, 3.293],
                    [0.289, -0.294]
                  ],
                  o: [
                    [-0.186, 0.165],
                    [-0.109, 2.297],
                    [-0.348, 2.989],
                    [-7.572, 0.851],
                    [-3.001, -0.337],
                    [-0.905, -7.777],
                    [0.348, -2.988],
                    [4.993, -0.561],
                    [0.366, 0.013],
                    [0, 0],
                    [0.007, -0.094],
                    [0.056, -0.441],
                    [-5.248, -0.187],
                    [-4.54, 0.511],
                    [-0.935, 8.039],
                    [0.534, 4.588],
                    [7.823, 0.878],
                    [4.541, -0.51],
                    [0.381, -3.277],
                    [0.009, -0.413],
                    [-0.768, 0.784]
                  ],
                  v: [
                    [18.241, 4.13],
                    [17.93, 4.776],
                    [17.368, 11.654],
                    [11.613, 17.36],
                    [-11.366, 17.36],
                    [-17.118, 11.654],
                    [-17.118, -11.755],
                    [-11.366, -17.461],
                    [3.811, -18.034],
                    [4.514, -18.669],
                    [4.624, -20.263],
                    [4.652, -20.542],
                    [3.962, -21.433],
                    [-11.742, -20.843],
                    [-20.48, -12.15],
                    [-20.48, 12.049],
                    [-11.742, 20.742],
                    [11.99, 20.742],
                    [20.73, 12.049],
                    [21.406, 2.188],
                    [20.622, 1.863]
                  ],
                  c: true
                },
                ix: 2
              },
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.729411764706, 0.729411764706, 0.729411764706, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [21.665, 22.274], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 }
            }
          ],
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          hd: false
        }
      ],
      ip: 0,
      op: 1000,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};
